import axios from 'axios'
import { ElMessage } from 'element-plus'
import baseUrl from './config'

const service = axios.create({
  // baseUrl: baseUrl,
  baseURL: baseUrl,
  timeout: 10000,
  // withCredentials: true,
})

// 拦截请求
service.interceptors.request.use(
  config => {
    // const { token } = store.state.app
    // if (token) {
    //   config.headers.Authorization = token
    // }
    return config
  },
  error => {
    // return Promise.reject(error)
    Promise.reject(error)
  }
)

// 拦截响应
service.interceptors.response.use(
  // 响应成功进入第1个函数,该函数的参数是响应对象
  response => {
    let res = response.data
    // code = 0 请求返回成功
    // console.log(res, 'res');

    if(res.code != 0) {
      ElMessage({
        message: res.message ?? res.msg,
        type: 'error',
        duration: 5 * 1000,
      })
  
      // 10001:无权限访问; 10002:令牌过期;  10003:非法的token  10004:令牌不存在;
      if (res.code === 10001 || res.code === 10002 || res.code === 10003) {
        // const redirect = encodeURIComponent(window.location.href)
        // router.push(`/login?redirect=${redirect}`)
        // // 清除token
        // store.dispatch('app/clearToken')
      }
    }
    return res
  },
  // 响应失败进入第2个函数，该函数的参数是错误对象
  async error => {
    ElMessage.error(error.message)
    return Promise.reject(error)
  }
)
export default service