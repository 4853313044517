import { createStore } from 'vuex'
import { getMenus } from '@/api/index'

export default createStore({
  state: {
    menuList: [
      // { id: 0, index: '/', name: '首页' },
      // { 
      //   id: 3,
      //   index: '/news/notice',
      //   name: '新闻动',
      //   children: [
      //     { id: 1, index: '/news/notice', name: '通知公告', ico: 'icon-tongzhi' },
      //     { id: 2, index: '/news/internal', name: '行业新闻', ico: 'icon-xinwen' },
      //     { id: 4, index: '/news/notice', name: '健康知识', ico: 'icon-gongyi' },
      //   ]
      // },
      // { 
      //   id: 5,
      //   index: '/introduce/organizing',
      //   name: '项目介绍',
      //   children: [
      //     { id: 6, index: '/introduce/organizing', name: '公益项目组织', ico: 'icon-gongyi' },
      //     { id: 7,  index: '/introduce/management', name: '公益项目管理', ico: 'icon-danwei' },
      //     { id: 8,  index: '/introduce/leader', name: '公益项目领导', ico: 'icon-xinwen' },
      //     { id: 9,  index: '/introduce/reagent', name: '公益项目试剂', ico: 'icon-xiazai' },
      //   ]
      // },
      // { 
      //   id: 10,
      //   index: '/policy',
      //   name: '政策法规',
      // },
      // { 
      //   id: 11,
      //   index: '/serve/center',
      //   name: '服务中心',
      //   children: [
      //     { id: 12,  index: '/serve/center', name: '服务处', ico: 'icon-gongyi' },
      //     { id: 13,  index: '/serve/support', name: '支持单位', ico: 'icon-danwei' },
      //     { id: 14,  index: '/serve/team', name: '专家团队', ico: 'icon-jiankangzhuanjia' },
      //     { id: 15,  index: '/serve/down', name: '下载中心', ico: 'icon-xiazai' },
      //   ]
      // },
      { id: 0, index: '/', name: '首页' },
      { 
        id: 3,
        index: '/list?id=1',
        name: '新闻动',
        children: [
          { id: 1, index: '/list?id=1', name: '通知公告', ico: 'icon-tongzhi' },
          { id: 2, index: '/list?id=2', name: '行业新闻', ico: 'icon-xinwen' },
          { id: 4, index: '/list?id=4', name: '健康知识', ico: 'icon-gongyi' },
        ]
      },
      { 
        id: 5,
        index: '/list?id=6',
        name: '项目介绍',
        children: [
          { id: 6, index: '/list?id=6', name: '公益项目组织', ico: 'icon-gongyi' },
          { id: 7,  index: '/list?id=7', name: '公益项目管理', ico: 'icon-danwei' },
          { id: 8,  index: '/list?id=8', name: '公益项目领导', ico: 'icon-xinwen' },
          { id: 9,  index: '/list?id=9', name: '公益项目试剂', ico: 'icon-xiazai' },
        ]
      },
      { 
        id: 10,
        index: '/list?id=10',
        name: '政策法规',
      },
      { 
        id: 11,
        index: '/list?id=12',
        name: '服务中心',
        children: [
          { id: 12,  index: '/list?id=12', name: '服务处', ico: 'icon-gongyi' },
          { id: 13,  index: '/list?id=13', name: '支持单位', ico: 'icon-danwei' },
          { id: 14,  index: '/list?id=14', name: '专家团队', ico: 'icon-jiankangzhuanjia' },
          { id: 15,  index: '/list?id=15', name: '下载中心', ico: 'icon-xiazai' },
        ]
      },
    ],
  },
  getters: {
  },
  mutations: {
    saveMenus(state, data) {
      data.unshift({ id: 0, index: '/', name: '首页' })
      data.map(item => {
        state.menuList.map(itm => {
          if(item.id == itm.id) {
            itm.name = item.name
          }
        })
      })
    }
  },
  actions: {
    async generateMenus(context) {//{ commit }, 
      const { code, data } = await getMenus({
        page_size: 100,
        page: 1,
        // name: ''
      })
      context.commit('saveMenus', data)
      if(!code) {
        context.commit('saveMenus', data)
      }
    },
  },
  modules: {
  }
})
