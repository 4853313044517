import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)

// 引入重置样式
import '@/../public/style/reset.css'

// 引入flexible.js
import '@/../public/js/flexible.js'

// 引入iconfont
import "@/assets/font/iconfont.css";

// 注册全局变量
import config from './utils/config'
app.config.globalProperties.$baseUrl = config

// createApp(App).use(store).use(router).mount('#app')
app.use(store).use(router).mount('#app')
