import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
// const Notice = () => import('@/views/news/notice.vue')
// const Internal = () => import('@/views/news/internal.vue')
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '首页'
    },  
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('@/views/list/index.vue'),
    meta: {
      title: '列表'
    },  
  },
  {
    path: '/list/detail',
    name: 'detail',
    component: () => import('@/views/list/detail.vue'),
    meta: {
      title: '详情'
    },  
  }
  // {
  //   path: '/news/notice',
  //   name: 'notice',
  //   component: () => import('@/views/news/notice.vue'),
  //   // component: Notice,
  //   meta: { title: '通知公告' }
  // },
  // {
  //   path: '/news/internal',
  //   name: 'internal',
  //   component: () => import('@/views/news/internal.vue'),
  //   // component: Home,
  //   meta: { title: '行业新闻' }
  // },

  // /******************** 项目介绍 ********************/
  // {
  //   path: '/introduce/committee',
  //   name: 'committee',
  //   component: () => import('@/views/introduce/committee.vue'),
  //   meta: { title: '公益项目组委会' }
  // },
  // {
  //   path: '/introduce/management',
  //   name: 'management',
  //   component: () => import('@/views/introduce/management.vue'),
  //   meta: { title: '公益项目管理' }
  // },
  // {
  //   path: '/introduce/leader',
  //   name: 'leader',
  //   component: () => import('@/views/introduce/leader.vue'),
  //   meta: { title: '公益项目领导' }
  // },
  // {
  //   path: '/introduce/organizing',
  //   name: 'organizing',
  //   component: () => import('@/views/introduce/organizing.vue'),
  //   meta: { title: '公益项目组织' }
  // },
  // {
  //   path: '/introduce/reagent',
  //   name: 'reagent',
  //   component: () => import('@/views/introduce/reagent.vue'),
  //   meta: { title: '公益项目试剂' }
  // },

  // /******************** 政策法规 ********************/
  // {
  //   path: '/policy',
  //   name: 'policy',
  //   component: () => import('@/views/policy/index.vue'),
  //   meta: { title: '政策法规' }
  // },

  // /******************** 服务中心 ********************/
  // {
  //   path: '/serve/center',
  //   name: 'center',
  //   component: () => import('@/views/serve/center.vue'),
  //   meta: { title: '服务处' }
  // },
  // {
  //   path: '/serve/support',
  //   name: 'support',
  //   component: () => import('@/views/serve/support.vue'),
  //   meta: { title: '支持单位' }
  // },
  // {
  //   path: '/serve/team',
  //   name: 'team',
  //   component: () => import('@/views/serve/team.vue'),
  //   meta: { title: '专家团队' }
  // },
  // {
  //   path: '/serve/down',
  //   name: 'down',
  //   component: () => import('@/views/serve/down.vue'),
  //   meta: { title: '下载中心' }
  // },
  // // {
  // //   path: '/serve/detail',
  // //   name: 'detail1',
  // //   component: () => import('@/views/serve/detail.vue'),
  // //   meta: { title: '专家详情' }
  // // },

  // /******************** 首页列表 ********************/
  // {
  //   path: '/list/health',
  //   name: 'listHealth',
  //   component: () => import('@/views/list/health.vue'),
  //   meta: { title: '健康科普' }
  // },
  // {
  //   path: '/list/welfare',
  //   name: 'listWelfare',
  //   component: () => import('@/views/list/welfare.vue'),
  //   meta: { title: '公益活动' }
  // },

  // /******************** 详情页 ********************/
  // {
  //   path: '/news/detail',
  //   name: 'publicDetail',
  //   component: () => import('@/views/news/detail.vue'),
  //   meta: { title: '健康科普' }
  // },
  // {
  //   path: '/notice/detail',
  //   name: 'detailNotice',
  //   component: () => import('@/views/news/detail.vue'),
  //   meta: { title: '通知公告' }
  // },
  // {
  //   path: '/internal/detail',
  //   name: 'detailNews',
  //   component: () => import('@/views/news/detail.vue'),
  //   meta: { title: '行内新闻' }
  // },
  // {
  //   path: '/health/detail',
  //   name: 'detailHealth',
  //   component: () => import('@/views/news/detail.vue'),
  //   meta: { title: '健康科普' }
  // },
  // {
  //   path: '/welfare/detail',
  //   name: 'detailWelfare',
  //   component: () => import('@/views/news/detail.vue'),
  //   meta: { title: '公益活动' }
  // },
  // {
  //   path: '/serve/detail',
  //   name: 'detailServe',
  //   component: () => import('@/views/serve/detail.vue'),
  //   meta: { title: '专家详情' }
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// console.log(router);
router.beforeEach((to) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
})

router.afterEach(() => {
  window.scrollTo(0,0);
})

export default router
