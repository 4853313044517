import { defineComponent, toRefs, reactive, onMounted, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import Header from '@/components/Header.vue';
import { getList } from '@/api/index';
import store from '@/store/index';
export default defineComponent({
  name: 'HomeView',
  components: {
    Header
  },

  setup() {
    const {
      proxy: ctx
    } = getCurrentInstance(); // 可以把ctx当成vue2中的this    

    const router = useRouter();
    onMounted(async () => {
      // 通知通告
      await getClumnList(1); // 新闻中心

      await getClumnList(2); // 健康科普

      await getClumnList(4); // 公益活动--公益组织

      await getClumnList(6); // 专家团队

      await getClumnList(14);
      state.navList = store.state.menuList;
    });
    const state = reactive({
      swiperList: [],
      noticeList: [],
      newsList: [],
      healthList: [],
      welfareList: [],
      tabsList: [],
      navList: [],

      tabsHandleClick(tab) {
        if (tab.props.label == '更多专家') {
          router.push({
            path: '/list',
            query: {
              id: 14
            }
          });
        }
      },

      gotoPage(item, path) {
        if (path) {
          localStorage.setItem('dataInfo', JSON.stringify(item));
          router.push(path);
        } else {
          router.push({
            path: 'list',
            query: {
              id: item
            }
          });
        }
      },

      gotoMenu(item) {
        if (item.name == '首页') {
          router.push('/');
          return;
        }

        router.push({
          path: '/list',
          query: {
            id: item.children ? item.children[0].id : item.id
          }
        });
      },

      getImage(url) {
        if (!url) {
          return;
        }

        let src = '';

        if (!url.indexOf('http')) {
          src = url;
        } else {
          src = ctx.$baseUrl + url;
        }

        return src;
      }

    });

    const getClumnList = async id => {
      let parmas = {
        page: 1,
        page_size: 10,
        column_id: id
      };
      const {
        data
      } = await getList(parmas);

      if (data.data.length) {
        data.data.map(item => {
          item.date = item.updated_at.split(' ')[0];
          item.day = changDate(item.updated_at).day;
          item.date = changDate(item.updated_at).date;
        });
      }

      if (id == 1) {
        // 通知公告
        state.noticeList = data.data; // let arr = [];
        // let index = data.data.length > 3? 3:data.data.length
        // for(let i = 0; i < index; i++) {
        //   arr.push({
        //     url: state.getImage(data.data[i].picture)
        //   })
        // }
        // state.swiperList = arr
      }

      if (id == 2) {
        // 新闻中心
        state.newsList = data.data;
      }

      if (id == 4) {
        // 健康科普
        state.healthList = data.data;
      }

      if (id == 6) {
        // 公益活动
        let arr = [];
        let index = data.data.length > 3 ? 3 : data.data.length;

        for (let i = 0; i < index; i++) {
          arr.push({
            url: state.getImage(data.data[i].picture)
          });
        }

        state.swiperList = arr;
        state.welfareList = data.data;
      }

      if (id == 14) {
        // 专家团队
        data.data.push({
          label: 'userMore',
          title: '更多专家',
          img: '',
          rich: ''
        });
        state.tabsList = data.data;
      }
    }; // 日期处理


    const changDate = str => {
      let dateArr = str.split(' ');
      let date = dateArr[0].split('-');
      let obj = {
        date: date[0] + '-' + date[1],
        day: date[2],
        time: dateArr[1]
      };
      return obj;
    };

    return { ...toRefs(state)
    };
  }

});