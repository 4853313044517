import { defineComponent, toRefs, reactive, onMounted, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import store from '@/store';
export default defineComponent({
  name: 'HeadNav',
  props: {
    activeMenu: {
      type: String,
      default: '/'
    }
  },

  setup(props, {
    emit
  }) {
    const {
      proxy: ctx
    } = getCurrentInstance(); // 可以把ctx当成vue2中的this

    const router = useRouter(); // const route = useRoute()

    onMounted(async () => {
      // state.getMenuList();
      await store.dispatch('generateMenus');
      state.menuList = store.state.menuList;
    });
    const state = reactive({
      // activeMenu: '/',
      // 导航菜单
      menuList: [{
        id: 0,
        index: '/',
        name: '首页'
      }, {
        id: 3,
        index: '/news/notice',
        name: '新闻动态' // children: [
        //   { id: 1, index: '/news/notice', name: '通知公告' },
        //   { id: 2, index: '/news/internal', name: '行业新闻' },
        //   { id: 4, index: '/news/notice', name: '健康知识' },
        // ]

      }, {
        id: 5,
        index: '/introduce/organizing',
        name: '项目介绍' // children: [
        //   { id: 6, index: '/introduce/organizing', name: '公益项目组织' },
        //   { id: 7,  index: '/introduce/management', name: '公益项目管理' },
        //   { id: 8,  index: '/introduce/leader', name: '公益项目领导' },
        //   { id: 9,  index: '/introduce/reagent', name: '公益项目试剂' },
        // ]

      }, {
        id: 10,
        index: '/policy',
        name: '政策法规'
      }, {
        id: 11,
        index: '/serve/center',
        name: '服务中心' // children: [
        //   { id: 12,  index: '/serve/center', name: '服务处' },
        //   { id: 13,  index: '/serve/support', name: '支持单位' },
        //   { id: 14,  index: '/serve/team', name: '专家团队' },
        //   { id: 15,  index: '/serve/down', name: '下载中心' },
        // ]

      }],

      gotoPage(item, itm) {
        // console.log(item);
        if (item.name == '首页') {
          router.push('/');
          return;
        } // emit('menus', item)


        if (itm) {
          router.push({
            path: '/list',
            query: {
              id: itm.id
            }
          }); // console.log(itm.id);
        } else {
          router.push({
            path: '/list',
            query: {
              id: item.children ? item.children[0].id : item.id
            }
          });
        }
      }

    });
    return { ...toRefs(state)
    };
  }

});