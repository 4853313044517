/**
 * request全局配置
 */

const config = {
  /**
   * api请求基础路径
   */
  base_url: {
    // 开发环境接口前缀
    // dev: 'http://iwnaq6.natappfree.cc',
    // dev: '/home',
    dev: 'http://api.jkfpzlzc.com',
    // 打包生产环境接口前缀
    pro: 'http://api.jkfpzlzc.com',
  },
}
// let mode = process.env.ENV
// const baseUrl = mode === 'dev' ? config.base_url.dev : config.base_url.pro
let mode = process.env.NODE_ENV
const baseUrl = mode === 'development' ? config.base_url.dev : config.base_url.pro

export default baseUrl
