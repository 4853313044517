import request from '@/utils/request'
// 获取登录用户权限菜单
export const getMenus = (listQuery) => {
  return request({
    url: '/home/column-list',
    method: 'get',
    params: listQuery,
  })
}

// 内容列表
export const getList = (listQuery) => {
  return request({
    url: 'home/article-list',
    method: 'get',
    params: listQuery,
  })
}
